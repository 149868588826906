import React from 'react';

import { colors, mq, fontSizes, fontWeights } from '../theme';
import { gtag } from '../helpers/gtag.js';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import NextWebinar from '../components/NextWebinar/NextWebinar';
import YourBrands from '../components/YourBrands';
import Container from '../components/Container';
import WebsiteButton from '../components/WebsiteButton';
import Concept from '../components/Concept';
import TickItem from '../components/TickItem';
import CustomerFeedback from '../components/CustomerFeedback';
import Referencement from '../components/Referencement';
import Team from '../components/Team';
import Link from '../components/Link';
import {
  Heading2,
  HeadingDivider,
  Paragraph
} from '../components/Heading/Text';
import Advantages from '../components/Advantages';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage';
import ContainerVideo from '../components/ContainerVideo';
import FooterBanner from '../components/FooterBanner/FooterBanner';

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Logiciel Devis, Factures, Suivi de Chantier BTP"
        description="Le logiciel de gestion le plus complet pour créer vos devis rapidement, facturer vos clients et suivre vos chantiers. Gagnez du temps. Augmentez vos marges. Web2vi accompagne les artisans du bâtiment pour améliorer la productivité de leur entreprise."
      >
        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": "Logiciel Devis, Factures, Suivi de Chantier BTP"
        }
        `}</script>
      </SEO>
      <Container background={colors.neutralWhite}>
        <Container.ContentLeft>
          <Heading2
            primary
            css={mq({ marginTop: [60, 0, 20] })}
            maxWidth={[500, '100%', '100%']}
            light
          >
            L’outil de gestion <span>le&nbsp;plus&nbsp;complet</span> pour les
            pros du bâtiment
          </Heading2>
          <Paragraph>
            Métrés, devis, factures, suivi de chantier générés en ligne en toute
            simplicité grâce à une bibliothèque d’ouvrages du bâtiment inégalée.
          </Paragraph>
          <span
            css={mq({
              display: ['inline', 'none']
            })}
          >
            <WebsiteButton
              href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
              trackLocation="Home Page - Module Titre"
              mb={4}
            >
              je demande ma démo gratuite
            </WebsiteButton>
          </span>
          <div
            css={mq({
              display: ['none', 'block'],
              textAlign: 'center'
            })}
          >
            <span
              css={mq({
                marginBottom: 25
              })}
            >
              <WebsiteButton
                href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
                trackLocation="Home Page - Module Titre"
              >
                demandez une démo
              </WebsiteButton>
            </span>
          </div>
        </Container.ContentLeft>
        <Container.ContentRight
          noMarge
          css={mq({
            marginRight: [-24, 24]
          })}
        >
          <BackgroundImage
            src="vincent.png"
            css={mq({
              display: ['block', 'none'],
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center',
              height: [600, 400, 500],
              marginTop: [0, 30]
            })}
          />
          <BackgroundImage
            src="technos.png"
            css={mq({
              display: ['none', 'block'],
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center',
              height: 200,
              marginBottom: 80
            })}
          />
        </Container.ContentRight>
      </Container>
      <Container
        padding={false}
        background={colors.neutralWhite}
        css={mq({
          position: 'relative',
          margin: `0 auto`,
          height: [150, 'auto'],
          maxWidth: 1140
        })}
      >
        <div
          css={{
            position: 'relative',
            top: -50,
            margin: 'auto',
            width: '95%'
          }}
        >
          <Concept />
        </div>
      </Container>
      <Container
        background={colors.neutralDarker}
        color={colors.neutralWhite}
        css={mq({ flexDirection: 'column', alignItems: 'center' })}
      >
        <Heading2>
          La <span>meilleure solution</span> web <br /> pour créer vos devis
          rapidement, <br /> suivre vos chantiers <br /> et facturer vos
          clients.
        </Heading2>
      </Container>
      <ContainerVideo
        id="module-1"
        ratio={[1920, 1012]}
        source="page-home-pub.mp4"
        placeholder="section-1-home.jpg"
        direction="right"
        background={colors.neutralWhite}
        trackLabel="Générale (Home page)"
      >
        <Heading2 light>
          Divisez par 3<br />
          le temps par devis
        </Heading2>
        <HeadingDivider />
        <Paragraph>
          Je gagne du temps grâce à la bibliothèque d’ouvrages qui
          contient&nbsp;:
        </Paragraph>
        <div
          css={mq({
            '> div': {
              lineHeight: ['initial', '100px']
            }
          })}
        >
          <TickItem>Les temps main d’oeuvre</TickItem>
          <TickItem>Les matériaux nécessaires</TickItem>
          <TickItem>Les infos de poses et de fournisseurs</TickItem>
          <TickItem>La compatibilité DTU</TickItem>
        </div>
        <div
          css={{
            marginTop: 60,
            display: 'flex',
            lineHeight: '24px'
          }}
        >
          <WebsiteButton
            variant="outline"
            href="/bibliotheque-ouvrages-chiffrage/"
          >
            En savoir plus
          </WebsiteButton>
        </div>
      </ContainerVideo>
      <Advantages />
      {/* BLOC */}
      <ContainerVideo
        ratio={[1920, 1012]}
        direction="left"
        source="page-home-general.mp4"
        placeholder="section-2-home.jpg"
        background={colors.neutralWhite}
        trackLabel="Présentation Web2vi (Home page)"
        css={mq({
          paddingBottom: ['', '0px']
        })}
      >
        <Heading2 light maxWidth={[380, '100%', '100%']}>
          À partir du devis ultra précis, tous vos documents pour le chantier en
          quelques clics
        </Heading2>
        <HeadingDivider />
        <Paragraph>
          Chiffrage infaillible : je ne perds pas d’argent avec des erreurs
          d’estimation de besoins en matériaux.
        </Paragraph>
        <div
          css={{
            marginTop: 60,
            display: 'flex',
            lineHeight: '24px'
          }}
        >
          <WebsiteButton variant="outline" href="#next-webinar">
            En savoir plus
          </WebsiteButton>
        </div>
      </ContainerVideo>
      <div css={{ backgroundColor: colors.neutralLighter }}>
        <Container
          css={mq({
            flexDirection: 'column'
          })}
        >
          <div
            css={mq({
              display: ['auto', 'block'],
              width: 900,
              height: '100%',
              position: 'absolute',
              left: '50vw',
              top: 0,
              bottom: 0
            })}
          >
            <BackgroundImage
              src="drone.png"
              css={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center center'
              }}
            />
          </div>
          <div css={mq({ marginRight: ['50%', 0] })}>
            <Heading2 light maxWidth={[380, '100%', '100%']}>
              Couvreurs, charpentiers, maçons
            </Heading2>
            <Paragraph>
              Pour encore plus d’efficacité, découvrez notre méthode de prise de
              mesure ultra rapide de vos cotes par drone en toute sécurité.
            </Paragraph>
            <WebsiteButton
              variant="outline"
              href="/drone-metre3D-batiment/"
              trackLocation="Home Page - Module drone"
            >
              Découvrez nos offres drone
            </WebsiteButton>
          </div>
        </Container>
      </div>
      <ContainerVideo
        id="module-3"
        ratio={[1920, 1012]}
        source="page-home-gestion-chantier.mp4"
        placeholder="section-3-home.jpg"
        background={colors.neutralWhite}
        trackLabel="Gestion de chantier (Home page)"
      >
        <Heading2 light maxWidth={[450, '100%', '100%']}>
          Gérez mieux vos chantiers, maximisez votre rentabilité
        </Heading2>
        <HeadingDivider />
        <Paragraph>
          Je sais immédiatement si mon chantier est rentable et combien de temps
          les équipes vont y passer.
        </Paragraph>
        <Paragraph>
          J’optimise ma marge et j’anticipe mes aléas pour être sûr de rentrer
          dans mes frais.
        </Paragraph>
        <div
          css={{
            marginTop: 60,
            display: 'flex',
            lineHeight: '24px'
          }}
        >
          <WebsiteButton variant="outline" href="/fonctionnalites-logiciel/">
            En savoir plus
          </WebsiteButton>
        </div>
      </ContainerVideo>
      <NextWebinar theme="dark" />
      <CustomerFeedback />
      <Team />
      <YourBrands />
      <p
        css={mq({
          backgroundColor: colors.brandColor,
          textAlign: ['center', 'left'],
          margin: `0 auto`,
          padding: '40px 19px',
          lineHeight: '48px',
          color: colors.neutralDarker,
          fontWeight: fontWeights.semiBold,
          fontSize: fontSizes.larger
        })}
      >
        Un gain de temps mesurable. Une bibliothèque inégalée.
        <br css={mq({ display: ['block', 'none'] })} />
        <Link
          onClick={() => {
            gtag('event', 'Click', {
              event_category: 'ExternalLink',
              event_label: 'Je demande ma démo gratuite (Footer)'
            });
          }}
          href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
        >
          <u>Je demande ma démo gratuite</u>
        </Link>{' '}
      </p>
      <Referencement />
      <FooterBanner />
    </Layout>
  );
};

export default IndexPage;
