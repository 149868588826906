import React, { useRef, useState } from 'react';
import { colors, mq, fontWeights, fontSizes } from '../../theme';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading2, HeadingDivider } from '../Heading/Text';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import Link from '../Link';
import Image from '../Image';

const Team = () => {
  const {
    allTeamsJson: { nodes: data }
  } = useStaticQuery(graphql`
    query TeamQuery {
      allTeamsJson(filter: { expertTeam: { eq: true } }) {
        nodes {
          id
          name
          job
          text: textExpert
          image: imageFun
        }
      }
    }
  `);

  const [currentIndex, setCurrentIndex] = useState(0);

  const slideTo = i => setCurrentIndex(i);

  const onSlideChanged = e => setCurrentIndex(e.item);

  const carouselRef = useRef(null);

  return (
    <div
      css={{
        overflow: 'hidden',
        backgroundColor: colors.neutralDarker,
        color: colors.neutralWhite
      }}
    >
      <div
        css={mq({
          boxSizing: 'border-box',
          padding: ['80px 0 85px', '30px 18px'],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: [800, 'auto'],
          margin: 'auto',
          '.alice-carousel__wrapper': {
            overflow: 'visible'
          }
        })}
      >
        <Heading2 maxWidth={760} textAlign="center">
          <span>Des pros du bâtiment</span> qui vous accompagnent ensuite tout
          au long de votre expérience
        </Heading2>
        <div css={{ display: 'flex' }}>
          {data.map(({ id }, itemIndex) => (
            <span
              role="button"
              tabIndex="0"
              key={`button-${id}`}
              onKeyPress={() => slideTo(itemIndex)}
              onClick={() => slideTo(itemIndex)}
              css={{ outline: 'none', cursor: 'pointer' }}
            >
              <HeadingDivider
                css={mq({
                  width: [60, 20],
                  marginLeft: [10, 4],
                  marginRight: [10, 4],
                  backgroundColor:
                    itemIndex !== currentIndex
                      ? colors.neutralWhite20
                      : colors.brandColor
                })}
              />
            </span>
          ))}
        </div>
        <AliceCarousel
          autoPlay
          autoPlayInterval={5000}
          buttonsDisabled
          dotsDisabled
          ref={carouselRef}
          slideToIndex={currentIndex}
          onSlideChanged={onSlideChanged}
          mouseTrackingEnabled
        >
          {data.map(({ id, image, name, text, job }) => (
            <div key={`element-${id}`}>
              <div
                css={mq({
                  overflow: 'hidden',
                  margin: '24px auto 52px',
                  backgroundColor: colors.neutralBlack,
                  maxWidth: '100%',
                  width: [747, 300],
                  height: [240, 'auto'],
                  borderRadius: 10,
                  display: 'flex',
                  flexDirection: ['row', 'column'],
                  justifyContent: 'center',
                  alignItems: 'center'
                })}
              >
                <BackgroundImage
                  src={image}
                  css={mq({
                    width: [240, 300],
                    height: [240, 240],
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top 0px center'
                  })}
                />
                <div css={{ flex: 1, padding: '40px 29px 40px 38px' }}>
                  <h2
                    css={{
                      color: colors.brandColor,
                      textTransform: 'uppercase',
                      fontWeight: fontWeights.semiBold,
                      lineHeight: '32px',
                      marginBottom: 8
                    }}
                  >
                    {name}
                  </h2>
                  <p
                    css={{
                      fontSize: fontSizes.semiBig,
                      fontWeight: fontWeights.semiBold,
                      lineHeight: '26px'
                    }}
                  >
                    {job}
                  </p>
                  <p
                    css={{
                      fontSize: fontSizes.semiBig,
                      lineHeight: '26px'
                    }}
                  >
                    {text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </AliceCarousel>
        <div
          css={{
            marginTop: 60,
            display: 'flex',
            lineHeight: '24px'
          }}
        >
          <div
            css={{
              marginRight: 12,
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colors.brandColor,
              borderRadius: '50%'
            }}
          >
            <Image src="down.svg" css={{ transform: 'rotate(180deg)' }} />
          </div>
          <Link
            to="/equipe/"
            css={{
              fontWeight: fontWeights.semiBold,
              textTransform: 'uppercase',
              textDecoration: 'underline',
              color: colors.neutralWhite,
              fontSize: fontSizes.semiBig
            }}
          >
            Découvrir l&apos;équipe web2vi
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Team;
