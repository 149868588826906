import React from 'react';
import { colors, fontSizes, fontWeights, mq } from '../../theme';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from '../Container';
import { Heading2, HeadingDivider, Paragraph } from '../Heading/Text';
import Image from '../Image';
import WebsiteButton from '../WebsiteButton';

const CustomerFeedback = () => {
  const {
    allClientJson: { nodes: data }
  } = useStaticQuery(graphql`
    query CustomerFeedBackQuery {
      allClientJson(limit: 3) {
        nodes {
          id
          text
          author
          avatar
        }
      }
    }
  `);

  const renderFeedback = ({ id, author, text, avatar }) => (
    <div
      key={id}
      css={mq({
        position: 'relative',
        display: 'flex',
        flexDirection: ['row', 'column'],
        alignItems: 'center',
        marginBottom: 30
      })}
    >
      <div
        css={{
          position: 'absolute',
          width: 116,
          height: 116
        }}
      >
        <Image
          src={avatar}
          alt={author}
          css={{
            width: '100%',
            height: '100%',
            borderRadius: 116
          }}
        />
      </div>
      <div
        css={mq({
          marginLeft: [58, 0],
          marginTop: [0, 58],
          padding: ['25px 20px 25px 80px', '80px 20px 25px'],
          backgroundColor: colors.neutralWhite
        })}
      >
        <h4>{text}</h4>
        <p
          dangerouslySetInnerHTML={{ __html: author }}
          css={{
            marginTop: 16,
            fontSize: fontSizes.medium,
            letterSpacing: 1,
            color: colors.neutralDarker
          }}
        />
      </div>
    </div>
  );

  return (
    <Container background={colors.neutralLighter}>
      <Container.ContentLeft css={mq({ display: ['block', 'none'] })} size={45}>
        <Heading2 light>Ce que nos clients pensent de&nbsp;nous</Heading2>
        <HeadingDivider />
        <Paragraph>
          Web2vi est un logiciel fait par des pros, pour vous !
          <br />
          Retrouvez ici l’avis de nos clients.
        </Paragraph>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 65,
            '& > button': { marginBottom: 20 }
          }}
        >
          <WebsiteButton
            href="https://web2vi.app/register"
            trackLocation="Home Page - Module Feedback"
          >
            Je demande ma démo gratuite
          </WebsiteButton>
        </div>
      </Container.ContentLeft>

      <Container.ContentRight
        size={55}
        css={mq({ display: ['block', 'none'], marginLeft: '5%' })}
      >
        {data.map(renderFeedback)}
        <div
          css={{
            marginTop: 60,
            display: 'flex',
            lineHeight: '24px'
          }}
        >
          <div
            css={{
              marginRight: 12,
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colors.brandColor,
              borderRadius: '50%'
            }}
          >
            <Image src="down.svg" css={{ transform: 'rotate(180deg)' }} />
          </div>
          <Link
            to="/temoignages/"
            css={{
              fontWeight: fontWeights.semiBold,
              textTransform: 'uppercase',
              textDecoration: 'underline',
              color: colors.neutralBlack,
              fontSize: fontSizes.semiBig
            }}
          >
            Voir plus
          </Link>
        </div>
      </Container.ContentRight>
      <div css={mq({ display: ['none', 'block'] })}>
        <div css={mq({ textAlign: ['left', 'center'] })}>
          <Heading2 light>Ce que nos clients pensent de nous</Heading2>
          <HeadingDivider />
        </div>
        {data.map(renderFeedback)}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: [65, 35],
            '& > button': { marginBottom: 20 }
          }}
        >
          <div
            css={mq({
              marginTop: [30, 0],
              marginBottom: [0, 10],
              display: 'flex',
              lineHeight: '24px'
            })}
          >
            <div
              css={{
                marginRight: 12,
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.brandColor,
                borderRadius: '50%'
              }}
            >
              <Image src="down.svg" css={{ transform: 'rotate(180deg)' }} />
            </div>
            <Link
              to="/temoignages/"
              css={{
                fontWeight: fontWeights.semiBold,
                textTransform: 'uppercase',
                textDecoration: 'underline',
                color: colors.neutralBlack,
                fontSize: fontSizes.semiBig
              }}
            >
              Voir plus
            </Link>
          </div>
          <WebsiteButton
            href="https://web2vi.app/register"
            trackLocation="Module témoignage"
          >
            Je demande ma démo gratuite
          </WebsiteButton>
        </div>
      </div>
    </Container>
  );
};

export default CustomerFeedback;
