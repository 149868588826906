import React from 'react';
import { colors, mq, MediaQuery } from '../../theme';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../Container';
import WebsiteButton from '../WebsiteButton';
import { Heading2, HeadingDivider } from '../Heading/Text';
import BackgroundImage from '../BackgroundImage/BackgroundImage';

const YourBrands = () => {
  const {
    allBrandsJson: { nodes: data }
  } = useStaticQuery(graphql`
    query MyQuery {
      allBrandsJson(limit: 10) {
        nodes {
          id
          image
        }
      }
    }
  `);

  return (
    <div
      css={{
        backgroundColor: colors.neutralWhite,
        color: colors.neutralDarker
      }}
    >
      <Container
        css={mq({ flexDirection: 'column', alignItems: ['center', 'inherit'] })}
      >
        <Heading2 light maxWidth={620} textAlign={['center', 'left']}>
          Retrouvez vos marques préférées dans notre base de données
        </Heading2>

        <HeadingDivider />
        <div
          css={mq({
            display: 'flex',
            justifyContent: 'space-between',
            flexFlow: 'row wrap',
            flexDirection: 'row',
            width: [1000, '100%', '100%'],
            marginBottom: 30
          })}
        >
          <MediaQuery>
            {({ isTablet }) =>
              data.slice(0, isTablet ? 9 : 10).map(item => (
                <BackgroundImage
                  src={item.image}
                  key={item.id}
                  css={mq({
                    width: ['20%', '50%', '33%'],
                    marginBottom: 50,
                    height: 162,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  })}
                />
              ))
            }
          </MediaQuery>
        </div>
        <WebsiteButton
          href="/partenaires/#tab-partenaires"
          variant="outline"
          trackLocation="Module partenaires"
        >
          En savoir plus
        </WebsiteButton>
      </Container>
    </div>
  );
};

export default YourBrands;
