import React, { useState, useRef } from 'react';
import { colors, mq, MediaQuery, fontWeights } from '../../theme';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useStaticQuery, graphql } from 'gatsby';
import WebsiteButton from '../WebsiteButton';
import { Heading3, HeadingDivider } from '../Heading/Text';
import Container from '../Container';
import PointImage from '../PointImage';
import { chunk } from '../../helpers/chunk.js';

const Advantages = () => {
  const {
    allAdvantagesJson: { nodes: data }
  } = useStaticQuery(graphql`
    query AdvantagesQuery {
      allAdvantagesJson(limit: 9) {
        nodes {
          id
          title
          image
        }
      }
    }
  `);

  const [currentIndex, setCurrentIndex] = useState(0);

  const slideTo = i => setCurrentIndex(i);

  const onSlideChanged = e => setCurrentIndex(e.item);

  const carouselRef = useRef(null);

  return (
    <div
      css={{
        backgroundColor: colors.neutralDarker,
        color: colors.neutralWhite,
        overflow: 'hidden'
      }}
    >
      <Container
        css={mq({
          flexDirection: 'column',
          alignItems: 'center'
        })}
      >
        <MediaQuery>
          {({ isMobile }) => (
            <div
              css={mq({
                boxSizing: 'border-box',
                display: ['flex'],
                flexDirection: 'column',
                alignItems: 'center',
                width: ['100%', '100%'],
                margin: 'auto',
                '.alice-carousel__wrapper': {
                  overflow: 'visible'
                }
              })}
            >
              <Heading3 textAlign="center">
                <span>9 raisons</span> qui vous feront choisir Web2vi
              </Heading3>

              <div css={{ display: 'flex', marginBottom: 60 }}>
                {Array.from({ length: isMobile ? 9 : 3 }).map(
                  (_, itemIndex) => (
                    <span
                      role="button"
                      tabIndex="0"
                      key={itemIndex}
                      onKeyPress={() => slideTo(itemIndex)}
                      onClick={() => slideTo(itemIndex)}
                      css={{ outline: 'none', cursor: 'pointer' }}
                    >
                      <HeadingDivider
                        css={mq({
                          width: [60, 20],
                          marginLeft: [10, 4],
                          marginRight: [10, 4],
                          backgroundColor:
                            itemIndex !== currentIndex
                              ? colors.neutralWhite20
                              : colors.brandColor
                        })}
                      />
                    </span>
                  )
                )}
              </div>

              <AliceCarousel
                autoPlay
                autoPlayInterval={5000}
                buttonsDisabled
                dotsDisabled
                ref={carouselRef}
                slideToIndex={currentIndex}
                onSlideChanged={onSlideChanged}
                mouseTrackingEnabled
              >
                {chunk(
                  data.map(({ image, title }, key) => (
                    <PointImage
                      width={['100%', '100%']}
                      key={key}
                      source={image}
                      number={`0${key + 1}`}
                    >
                      <span
                        css={{
                          textTransform: 'uppercase',
                          fontWeight: fontWeights.semiBold
                        }}
                      >
                        {title}
                      </span>
                    </PointImage>
                  )),
                  isMobile ? 1 : 3
                ).map((slide, index) => (
                  <div
                    key={index}
                    css={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    {slide}
                  </div>
                ))}
              </AliceCarousel>
            </div>
          )}
        </MediaQuery>
        <div css={mq({ display: ['block', 'none'] })}>
          <WebsiteButton
            href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
            trackLocation="Home Page - Module 9 raisons"
          >
            je demande ma démo gratuite
          </WebsiteButton>
        </div>
      </Container>
    </div>
  );
};

export default Advantages;
