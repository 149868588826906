/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { gtag } from '../../helpers/gtag.js';
import { fontWeights, fontSizes, mq, colors } from '../../theme';
import Image from '../Image';

const DecorateLink = ({ trackLabel, ...props }) => {
  const handleClick = () => {
    gtag('event', 'Click', {
      event_category: 'Link',
      event_label: `Home page - ${trackLabel}`
    });
  };
  return (
    <div
      css={mq({
        marginTop: [40, 0],
        display: 'flex',
        lineHeight: '24px'
      })}
    >
      <div
        css={{
          marginRight: 12,
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${colors.neutralBlack}`,
          borderRadius: '50%'
        }}
      >
        <Image
          src="down.svg"
          alt="More"
          css={{ transform: 'rotate(180deg)' }}
        />
      </div>
      {
        <a
          {...props}
          onClick={handleClick}
          css={{
            fontWeight: fontWeights.semiBold,
            textTransform: 'uppercase',
            color: colors.neutralBlack,
            fontSize: fontSizes.medium
          }}
        />
      }
    </div>
  );
};

const ConceptItem = ({
  title,
  color = colors.brandColor,
  radiusLeft = false,
  radiusRight = false,
  anchor,
  source
}) => {
  return (
    <div
      css={mq({
        flex: 1,
        padding: ['40px 20px', '24px 15px', '24px 13px'],
        backgroundColor: color,
        transition: 'transform 200ms ease-in-out',
        borderTopLeftRadius: radiusLeft && [12, 0],
        borderBottomLeftRadius: radiusLeft && [12, 0],
        borderTopRightRadius: radiusRight && [12, 0],
        borderBottomRightRadius: radiusRight && [12, 0],
        ':hover': {
          transform: ['scale3d(1.1, 1.1, 1)', 'none'],
          boxShadow: '0 32px 54px -30px rgba(0, 0, 0, 0.5)'
        }
      })}
    >
      <div
        css={mq({
          height: '100%',
          display: ['flex', 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between'
        })}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Image
            src={source}
            alt="Logo Web2vi"
            css={{ width: 100, fill: '#434343' }}
          />
        </div>
        <p
          css={{
            lineHeight: '35px',
            fontSize: fontSizes.large,
            fontWeight: fontWeights.regular,
            textAlign: 'center',
            color: '#434343'
          }}
        >
          {title}
        </p>
      </div>
      <div css={mq({ display: ['none', 'block'] })}>
        <DecorateLink
          href={anchor}
          trackLabel={`Dites-moi comment ${title.toLowerCase()}`}
        >
          <span
            css={{
              fontWeight: fontWeights.bold,
              fontSize: fontSizes.large,
              textTransform: 'none',
              textDecoration: 'none'
            }}
          >
            {title}
          </span>
        </DecorateLink>
      </div>
    </div>
  );
};

const Concept = () => {
  return (
    <div
      css={mq({
        display: 'flex',
        backgroundColor: colors.brandColor,
        color: colors.neutralDarker,
        flexDirection: ['row', 'column'],
        overflow: [null, 'hidden'],
        borderRadius: 12
      })}
    >
      <ConceptItem
        radiusLeft
        title="Je gagne du temps"
        color="#FDDA74"
        anchor="#module-1"
        source="advantages/gain_de_temps.svg"
      />
      <ConceptItem
        title="J’augmente mes marges"
        color="#FCCE46"
        anchor="#module-3"
        source="advantages/pilotage_entreprise.svg"
      />
      <ConceptItem
        radiusRight
        title="Je signe plus de chantiers"
        anchor="#next-webinar"
        source="advantages/personnalisation.svg"
      />
    </div>
  );
};

export default Concept;
